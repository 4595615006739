/*** IMPORTS FROM imports-loader ***/
var define = false;

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { locoScroll } from "./smooth-scrolling";
gsap.registerPlugin(ScrollTrigger);

export function timelineScroll() {

  let container = document.querySelector(".timeline__slider");

  gsap.to(container, {
    x: () => -(container.scrollWidth - document.documentElement.clientWidth + 100) + "px",
    scrollTrigger: {
      trigger: '.timeline',
      scroller: ".smooth-scroll",
      invalidateOnRefresh: true,
      pin: true,
      scrub: 1,
      end: () => "+=" + container.offsetWidth
    }
  });


  // let timelineWrap = document.querySelector(".timeline");
  // let timelineWrapWidth = timelineWrap.offsetWidth;
  // let horizontalScrollLength = timelineWrapWidth - window.innerWidth;

  // function resize() {
  //   timelineWrapWidth = timelineWrap.offsetWidth;
  //   horizontalScrollLength = timelineWrapWidth - innerWidth;
  // }

  // gsap.to(container, {
  //   scrollTrigger: {
  //     scroller: ".smooth-scroll",
  //     scrub: true,
  //     trigger: ".timeline",
  //     pin: true,
  //     start: "top top",
  //     end: () => `+=${timelineWrapWidth}`, // Functional value to make sure it updates on refresh
  //     invalidateOnRefresh: true // Invalidate the tween as well
  //   },
  //   x: () => -horizontalScrollLength
  // });

  // ScrollTrigger.addEventListener("refreshInit", resize);
  // ScrollTrigger.addEventListener("refresh", () => locoScroll.update());
  // ScrollTrigger.refresh();
}

