/*** IMPORTS FROM imports-loader ***/
var define = false;

import $ from 'jquery';
import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
gsap.registerPlugin(SplitText, ScrollTrigger, DrawSVGPlugin);
import imagesLoaded from 'imagesloaded';
import { locoScroll } from "./modules/smooth-scrolling";

// Cursor stuff
import Cursor from "./modules/cursor";
import Magnetic from "./modules/magnetic";

// $('[data-magnetic]').each(function () {new Magnetic(this);}); --> moved this to the end to run after all elements are loaded -> need to figure out how to refire

import './modules/smooth-scrolling';
imagesLoaded(document.querySelector('body'), function (instance) {
  locoScroll.update();
});

// Animate On Scroll
import { animateOnScroll } from './modules/animateOnScroll.js';
animateOnScroll();

// Don't load on mobile
function is_touch_enabled() {
  return ( 'ontouchstart' in window ) ||
         ( navigator.maxTouchPoints > 0 ) ||
         ( navigator.msMaxTouchPoints > 0 );
}

// Inline Video Controls
import { desktopReel } from './modules/reel.js';
import { mobileReel } from './modules/reel.js';

if( ! is_touch_enabled() ) {
  // alert('this is not touch enabled');

  // Set up cursor
  const cursor = new Cursor();
  $('[data-magnetic]').each(function () {new Magnetic(this);});

  // Inline Video Controls
  var reelExists = document.querySelector('.reel');
  if (reelExists) {
    desktopReel();
    reelExists.classList.add('nonTouch');
  }

} else {
  // mobile stuff
  $('body').addClass('touch');
  mobileReel();
}


import { loadHeroImage } from './modules/heroGrid';
loadHeroImage();

import { heroSplitText } from './modules/heroSplitText';
heroSplitText();

var tl = gsap.timeline();
tl.set('svg', {
  visibility: 'visible'
});
tl.from('.b', 2, {
  drawSVG: 0,
  delay: 0.5,
  ease: 'Expo.easeOut'
});
tl.to('.b', 1, {
  fill:"white",
  ease: 'Expo.easeOut'
}, 2.5);
tl.to('.hero__title--svg', 1, {
  scale: 1,
  ease: 'Expo.easeOut'
}, 2.5);


import { cheersSplit } from './modules/cheersSplit';
cheersSplit();


import { timelineScroll } from './modules/timelineScroll.js';
var timelineSliderExists = document.querySelector('.timeline__slider');
if( ! is_touch_enabled() ) {
  if (timelineSliderExists) {
    timelineScroll();
  }
}

// Skip timeline
$('.timeline__skip').on('click tap', function(e) {
  e.preventDefault();
  locoScroll.scrollTo(document.querySelector('.stats'));
});

// re-fire stuff on resize
// var resizeTimer;
// window.onresize = function () {
//   clearTimeout(resizeTimer);
//   resizeTimer = setTimeout(function () {
//     // alert('firing');
//     ScrollTrigger.addEventListener("refresh", () => locoScroll.update());
//     ScrollTrigger.refresh();
//     locoScroll.update();
//     loadHeroImage();
//     timelineScroll();
//     heroSplitText();
//   }, 250);
// }
