/*** IMPORTS FROM imports-loader ***/
var define = false;

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import $ from 'jquery';
require('../vendor/magnific.js');

// first time you click, it starts over and plays
// once playing, if you click, it just mutes it
// re-clicking un-mutes it where it's currently at (then that's how it works moving forward)

export function desktopReel() {
  let reelContainer = document.querySelector('.reel');
  let videoElem = document.getElementById("reel__bRoll");
  let videoFull = document.getElementById("reel__fullVideo");
  let playButton = document.querySelector(".playPauseVideo");

  playButton.addEventListener("click", handlePlayButton, false);
  // playVideo();

  // autoplya broll
  async function playVideo() {
    try {
      await videoElem.play();
      playButton.classList.add("playPauseVideo--playing");
    } catch (err) {
      playButton.classList.remove("playPauseVideo--playing");
    }
  }

  // Play full Video
  async function playFullVideo() {
    try {
      await videoFull.play();
    } catch (err) {
      // error
    }
  }

  function handlePlayButton() {

    if (videoElem.paused) {
      playVideo();
    } else {
      videoElem.pause();
      playButton.classList.remove("playPauseVideo--playing");
    }
  }

  // pause video on esc
  document.onkeydown = function (evt) {
    evt = evt || window.event;
    if (evt.keyCode == 27) {
      handlePlayButton();
    }
  };

  // Play full video on click
  reelContainer.addEventListener('click', function () {
    if (reelContainer.classList.contains('firstClick')) {
      videoElem.pause();
      videoElem.currentTime = 0;
      videoElem.muted = false;
      videoElem.play();
      reelContainer.classList.remove('firstClick');
      reelContainer.classList.add('unMuted');
      document.querySelector('body').classList.toggle('playing-project-video');
    } else {
      if (reelContainer.classList.contains('unMuted')) {
        // alert('it is playing');
        // since it's playing, we want to mute it
        videoElem.muted = true;
        reelContainer.classList.remove('unMuted');
        // adding this class allows us to animate everything with css
        document.querySelector('body').classList.toggle('playing-project-video');
      } else {
        // alert('it was not playing');
        // it's muted, so we want to un-mute it
        videoElem.muted = false;
        reelContainer.classList.add('unMuted');
        document.querySelector('body').classList.toggle('playing-project-video');
      }
    }
  });


  // animate it on scroll
  // ScrollTrigger.create({
  //   scroller: ".smooth-scroll",
  //   trigger: ".reel",
  //   start: "top top",
  //   end: "bottom 60%",
  //   pin: ".reel"
  // });

  gsap.to('.reel__backgroundVideo', {
    opacity: 1,
    duration: .6,
    ease: "power4.out",
    scrollTrigger: {
      scroller: ".smooth-scroll",
      trigger: '.reel',
      start: 'top 40%',
      end: 'bottom 40%',
      // markers: true,
      onEnter: function () {
        playVideo();
      },
      onLeave: function () {
        videoElem.pause();
        playButton.classList.remove("playPauseVideo--playing");
        reelContainer.classList.remove('reel--playing');
        videoFull.pause();
        document.querySelector('body').classList.remove('playing-project-video');
      },
      onEnterBack: function() {
        videoElem.play();
      },
      onLeaveBack: function() {
        videoElem.pause();
        playButton.classList.remove("playPauseVideo--playing");
        reelContainer.classList.remove('reel--playing');
        videoFull.pause();
        document.querySelector('body').classList.remove('playing-project-video');
      }
    }
  });
}

export function mobileReel() {
  var reelFull = document.getElementById("reel__fullVideo");
  $(".reel__playPauseVideo").magnificPopup({
    type: "inline",
    midClick: true,
    callbacks: {
      open: function () {
        reelFull.play();
      },
      close: function () {
        reelFull.pause();
      }
    }
  });
}
